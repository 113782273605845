import React, { Component } from 'react'
import PropTypes from 'prop-types'
//import Client from 'shopify-buy'
import { StaticQuery, graphql } from "gatsby"

import '../assets/scss/main.scss'

class Layout extends Component {

  /*

  state = {
    isCartOpen: false,
    cartRequest: false,
    checkout: { lineItems: [] },
    products: [],
    shop: {}
  }

  componentDidMount() {
    this.Cookies = require('cookies-js')

    let checkoutId = this.Cookies.get('checkoutId')

    this.client = Client.buildClient({
      domain: 'checkout.easyblinds.com.au',
      storefrontAccessToken: 'e116dc7f96cbe9b9b2e3e32bf3dab7b4'
    })

    if (checkoutId) {
      // Find old checkout
      this.client.checkout.fetch(checkoutId).then((checkout) => {
        // Do something with the checkout
        if ( checkout.lineItems ) {
          this.setState({ checkout })
        }
      }).catch(error => {
        // Checkout expired, initialise new one
        this._initialiseCart()
      });
    } else {
      // Initialise new Checkout
      this._initialiseCart()
    }
  }

  _initialiseCart() {
    this.client.checkout.create().then((checkout) => {
      // Do something with the checkout
      this.setState({ checkout })
      this.Cookies.set('checkoutId', checkout.id)
    })
  }

  _addVariantToCart(variantId, quantity, customAttributes){
    this.setState({ isCartOpen: true, cartRequest: true })

    const lineItemsToAdd = [{variantId, quantity: parseInt(quantity, 10), customAttributes}]
    const checkoutId = this.state.checkout.id

    return this.client.checkout.addLineItems(checkoutId, lineItemsToAdd)
      .then(checkout => this.setState({ checkout, cartRequest: false }))
  }

  _updateQuantityInCart(lineItemId, quantity) {
    const checkoutId = this.state.checkout.id
    const lineItemsToUpdate = [{id: lineItemId, quantity: parseInt(quantity, 10)}]
    this.setState({ isCartOpen: true, cartRequest: true })

    return this.client.checkout.updateLineItems(checkoutId, lineItemsToUpdate)
      .then(checkout => this.setState({ checkout, cartRequest: false }))
  }

  _removeLineItemInCart(lineItemId) {
    const checkoutId = this.state.checkout.id
    this.setState({ isCartOpen: true, cartRequest: true })

    return this.client.checkout.removeLineItems(checkoutId, [lineItemId])
      .then(checkout => this.setState({ checkout, cartRequest: false }))
  }
  */

  render() {
    let { children, pageContext } = this.props
    // let { checkout, cartRequest } = this.state

    // children = React.Children.map(children, (child) =>
    //   React.cloneElement(child, {
    //     addVariantToCart : this._addVariantToCart.bind(this),
    //     updateQuantityInCart : this._updateQuantityInCart.bind(this),
    //     removeLineItemInCart : this._removeLineItemInCart.bind(this),
    //     checkout : checkout,
    //   })
    // )
    return (
      <StaticQuery
        query={graphql`
          query SettingsQuery {
            site {
              siteMetadata {
                title
              }
            }
          }
        `}
        render={(data) => {
          return (
            <>
              <main>{ children }</main>
            </>
          )
        }}
      />
    )
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
